const body = document.getElementById("body");


function isIE() {
  ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  
  return is_ie; 
}
/* Create an alert to show if the browser is IE or not */
if (isIE()){
  body.classList.add("IE");
} else {
  const footerHeight = document.getElementById("footer").offsetHeight;
  body.style.paddingBottom = footerHeight + "px";
}




var theLetters = "01";
var ctnt = "0100100001100101011011000110110001101111001000000111011101101111011100100110110001100100"; // Your text goes here
var speed = 7; // ms per frame
var increment = 2; // frames per step. Must be >2

    
var clen = ctnt.length;       
var si = 0;
var stri = 0;
var block = "";
var fixed = "";

var wrapper = document.getElementById("output");

(function rustle (i) {          
  setTimeout(function () {
    if (--i){rustle(i);}
    nextFrame(i);
    si = si + 1;        
  }, speed);  
})(clen*increment+1); 


function nextFrame(pos){
  for ( var i=0; i < clen-stri; i++) {
    var num = Math.floor(theLetters.length * Math.random());

    var letter = theLetters.charAt(num);
    block = block + letter;
  }
  if (si == (increment - 1)) {
    stri++;
  }
  if (si == increment) {
    fixed = fixed + ctnt.charAt(stri - 1);
    si = 0;
    document.getElementById("output").innerHTML = fixed + block;
    block = "";
  }
};

const scroll = document.getElementById("scrollDown");
const heroHeight = document.getElementById("hero").offsetHeight;
scroll.addEventListener("click", function (event){
  event.preventDefault();
  scroller(heroHeight)
});

const scroll2 = document.getElementById("workscroll");
const scroll2Height = document.getElementById("workwithus").offsetTop;
scroll2.addEventListener("click", function (event){
  event.preventDefault();
  scroller(scroll2Height)
});
const scroller = function(length) {
  var scrollLength = length;
  if (window.outerWidth >= 1000) {
    scrollLength = length - 120;
  }
  window.scroll({
    top: scrollLength,
    left: 0,
    behavior: 'smooth'
  });
}

